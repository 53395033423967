export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '이름',
        field: 'name',
        sort: true
    },
    {
        label: '아이디',
        field: 'id',
        sort: false
    },
    {
        label: '성별',
        field: 'gender',
        sort: false,
    },
    {
        label: '전화번호',
        field: 'mobile',
        sort: false,
    },
    {
        label: '이메일',
        field: 'email',
        sort: false,
    },
    {
        label: '최근 접속 일자',
        field: 'lastLog',
        sort: false
    },
];
